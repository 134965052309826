<template>
  <!-- <div class="login"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <div class="login-box">
    <div class="title">请重置您的登录密码</div>
    <input
      type="password"
      id="pwd"
      v-model="pwd"
      placeholder="登录密码"
      autocomplete="off"
    />
    <input
      type="password"
      id="repwd"
      v-model="repwd"
      placeholder="确认登录密码"
      autocomplete="off"
    />
    <div class="login-btn" @click="toFindres">
      <img src="../../img/登录_slices/矩形 2 拷贝 2.png" alt="" />
      <span>确认修改</span>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import $ from "jquery";
import * as util from "@/unit/network";
export default {
  name: "Login",
  data() {
    return {
      checked: false,
      pwd: "",
      repwd: "",
      // code: "", // 验证码
    };
  },
  mounted() {},
  methods: {
    toFindres() {
      util.post(
        "/forgetPassword",
        {
          phone: localStorage.getItem("phone"),
          code: localStorage.getItem("code"),
          password: this.repwd,
        },
        (res) => {
          console.log(res);
          localStorage.removeItem("phone");
          localStorage.removeItem("code");
          this.$router.push("/login/findres");
        }
      );
    },
    // 微信注册
    wxLogin() {
      this.$router.push("/login/wxcode");
    },
  },
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login-box {
  width: 630/96in;
  height: 738/96in;
  background: url("../../img/登录_slices/组3.png") no-repeat;
  position: fixed;
  top: 50%;
  transform: translateY(-49%);
  right: 111px;
  text-align: center;
  padding: 108/96in 90/96in 80/96in;
  .title {
    font-size: 30/96in;
    font-weight: bold;
    color: #121212;
    height: 30/96in;
    line-height: 30/96in;
    letter-spacing: 0.1em;
    margin-bottom: 64/96in;
  }
  input {
    width: 450/96in;
    height: 58/96in;
    box-shadow: 0 12/96in 29/96in 3/96in rgba(35, 24, 21, 0.08);
    // opacity: 0.75;
    border-radius: 5/96in;
    padding-left: 54/96in;
    border: none;
    outline: none;
    font-size: 16/96in;
    font-weight: 400;
    color: #000;
    margin-bottom: 50/96in;
    &#pwd,
    &#repwd {
      background: rgba(253, 253, 253, 0.75)
        url("../../img/登录_slices/密码 .png") 19/96in no-repeat;
    }
  }
  // 记住密码
  //   .choose {
  //     display: flex;
  //     justify-content: space-between;
  //     padding: 0 13/96in;
  //     color: #313339;
  //     font-size: 14/96in;
  //     margin-bottom: 10/96in;
  //     height: 18/96in;
  //     label {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       /deep/ .el-checkbox__label {
  //         font-size: 14/96in;
  //         padding-left: 20/96in;
  //         color: #313339;
  //       }
  //     }
  //     .right {
  //       span {
  //         cursor: pointer;
  //       }
  //     }
  //   }

  // 确认修改密码
  .login-btn {
    position: relative;
    height: 116/96in;
    margin-top: 30/96in;
    cursor: pointer;
    img {
      position: absolute;
      left: -29/96in;
    }
    span {
      position: absolute;
      color: #edeef5;
      font-size: 20/96in;
      left: 50%;
      top: 36/96in;
      transform: translateX(-50%);
    }
  }
  // line
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14/96in;
    color: #313339;
    height: 14/96in;
    .line1 {
      width: 150/96in;
      height: 2/96in;
      background: #c1c1c1;
    }
    .text {
      margin: 0 27/96in;
    }
  }
  .login-wx {
    margin-top: 23/96in;
    height: 46/96in;
    cursor: pointer;
  }
}
.codebox {
  position: relative;
  .code {
    position: absolute;
    right: 22/96in;
    top: 19/96in;
    color: #4461f2;
    font-size: 16/96in;
    cursor: pointer;
  }
}
</style>
